import React from "react";
import GdprImage from "../../assets/image/home-3/gdpr.png";
import CcpaImage from "../../assets/image/home-3/ccpa.jpeg";

const ContentThree = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
        <div
            className="col-xl-5 col-lg-6 col-md-7 col-xs-10"
            data-aos="fade-left"
            data-aos-delay={0}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0 text-center">
              <h2 className="font-size-10 mb-8">Privacy First</h2>
              <p className="font-size-6 mb-0">
                We take data privacy very seriously. We never store content or conversations from your community, and our tools and APIs follow strict data regulations by GDPR and CCPA.  
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <img src={GdprImage} height={100} className="mr-10 ml-6 ml-lg-16" />
          <img src={CcpaImage} height={100}/>
        </div>
      </div>
    </div>
  );
};

export default ContentThree;
