import React from "react";
import imageOne from "../../assets/image/home-3/png/app.png";
import imageThree from "../../assets/image/home-3/png/big-green-circle-img.png";
import DiscordIcon from "../../components/Icons/DiscordIcon";
import SlackIcon from "../../components/Icons/SlackIcon";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-xs-9"
            data-aos="fade-right"
            data-aos-delay={0}
          >
            <div className="l3-content-image-5 mt-5 mt-lg-0">
              <div className="img-1">
                <img className="w-100" src={imageOne} alt="" />
              </div>
              <div className="img-3">
                <img className="w-100" src={imageThree} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-5 col-lg-6 col-md-7 col-xs-10"
            data-aos="fade-left"
            data-aos-delay={0}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-8">No-code Plugins</h2>
              <p className="font-size-6 mb-0">
                You can easily add a chat plugin without writing a single line
                of code. Modwise provides plugins for a number of platforms
                including Discord and Slack
              </p>
              <div className="mt-4">
                <DiscordIcon className="mr-6" />
                <SlackIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
