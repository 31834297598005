import React, { useContext } from "react";
import { Link } from "gatsby";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/home3/Hero";
import Company from "../sections/home3/Company";
import Feature from "../sections/home3/Feature";
import ContentOne from "../sections/home3/ContentOne";
import ContentTwo from "../sections/home3/ContentTwo";
import Review from "../sections/home3/Review";
import Pricing from "../sections/home3/Pricing";
import GlobalContext from "../context/GlobalContext";
import ContentThree from "../sections/home3/ContentThree";
import Questions from "../sections/home3/Questions";
import BookDemo from "../sections/home3/BookDemo";

const Home3 = () => {
  const {session} = useContext(GlobalContext);
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: session && (
            <Link className="btn btn-blue-3 btn-2 rounded-5" to={"/app"}>
              Go to Dashboard
            </Link>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero className="bg-default-3 pt-25 pt-lg-29" />
        <Company className="pt-12 pb-9" />
        <Feature className="pt-4 pt-lg-18 pb-15 pb-lg-25 border-bottom border-default-color-3" />
        <ContentOne className="pt-27 pt-lg-35 pb-lg-34 " />
        <ContentTwo className="pb-13 pb-lg-34" />
        <ContentThree className="pb-13 pb-lg-20" />
        <Review className="bg-default-3 pt-13 pt-lg-25 pb-9 pb-lg-25" />
        {/* <Pricing className="pt-13 pt-lg-25 pb-8 pb-lg-22 " /> */}
        <BookDemo className="pt-13 pt-lg-25 pb-8 pb-lg-22"/>
        <Questions className="pt-13 pt-lg-25 pb-8 pb-lg-22 " />
      </PageWrapper>
    </>
  );
};
export default Home3;
