import React from "react";

const Questions = (props) => {
  return (
    <div id="accordion" {...props}>
      <div className="container">
        <div
          className="mb-10 mb-lg-10 text-center text-lg-left"
          data-aos="fade-right"
          data-aos-delay={0}
        >
          <h2 className="font-size-10 pr-md-10 pr-xl-0 mb-0">FAQs</h2>
        </div>
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                class="btn btn-link"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                How do you ensure data privacy?
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">
              We don't store chat, conversations or any user-generated content
              in the first place. Our process-and-forget AI models only use this
              information to calculate scores of different harmful categories
              and never the information itself.
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Can I get direct access to the APIs?
              </button>
            </h5>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div class="card-body">
              Yes :)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
