import React, { useState } from "react";

const BookDemo = () => {
  const [email, setEmail] = useState("");
  return (
    <div className="container text-center">
      <h2 className="font-size-10 mt-20 mb-20">Book a quick demo now</h2>
      <div className="input-group book-demo-input-group w-md-75 w-sm-100 m-auto">
        <input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          className="form-control form-control-lg"
          placeholder="Enter your email"
          style={{
            borderBottomLeftRadius: "0.7rem",
            borderTopLeftRadius: "0.7rem",
          }}
        />
        <div className="input-group-append">
          <a
            className="btn btn-outline-secondary btn-blue-3 btn-4"
            style={{
              height: "71px",
              borderBottomRightRadius: "0.7rem",
              borderTopRightRadius: "0.7rem",
            }}
            target="_blank"
            href={`https://calendly.com/farooqar/15min?email=${email}`}
          >
            Book Demo
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookDemo;
